<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>PO Date From</label>
                <DatePicker v-model="state.optionFields.poStartDate.val"
                            :inputAttributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>PO Date To</label>
                <DatePicker v-model="state.optionFields.poEndDate.val"
                            :inputAttributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Delivery Date From</label>
                <DatePicker v-model="state.optionFields.deliveryStartDate.val"
                            :inputAttributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Delivery Date To</label>
                <DatePicker v-model="state.optionFields.deliveryEndDate.val"
                            :inputAttributes="{class: 'form-control'}"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">
                        {{ location }}
                    </option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Purchased Item</label>
                <Typeahead  cdi_type="material" v-bind:ID.sync="state.optionFields.purchasedItemId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Inventory Category</label>
                <select class="form-control" v-model="state.optionFields.inventoryCategory.val">
                    <option value="0">All</option>
                    <option v-for="[id, category] in cache.materialCache.MATERIAL_CATEGORIES" v-bind:key="id"
                            :value="id">{{ category }}
                    </option>
                </select>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Asset</label>
                <Typeahead ref="asset" cdi_type="asset" v-bind:ID.sync="state.optionFields.assetId.val"></Typeahead>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.ato.val">
                    ATO</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null
                              v-model="state.optionFields.corporate.val"> Corporate</label>
            </div>
            <div class="col-xs-12">
                <button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">
                    {{ (toggleHide) ? 'More' : 'Less' }} Filters
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {store} from '@/store/Report.store.js';
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import materialCache from '@/cache/material.cache.js'
import DatePicker from "../utils/DatePicker";
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
    name: 'Options',
    components: {
        Typeahead,
        DatePicker
    },
    data() {
        return {
            state: store.state,
            toggleHide: true,
            cache: {
                cdiLocationCache,
                materialCache
            }
        }
    },
    methods: {
        toggleMoreFilters: function () {
            this.toggleHide = !this.toggleHide;
            if(this.toggleHide){
                this.$refs.asset.object = '';
            }
        },
    },
    watch:{
        toggleHide: function () {
            this.state.toggleHide = this.toggleHide;
        }
    }
}
</script>