<template>
    <div>
        <h1>Purchase Orders</h1>
        <form @submit.prevent="runReport">
            <Options/>
            <GroupBy/>
            <Fields/>
            <Results/>
        </form>
    </div>
</template>

<script>
import {store} from '@/store/Report.store.js'
import GroupBy from "./utils/GroupBy";
import Fields from "./utils/Fields";
import Results from "./utils/Results";
import Options from "./purchase_order_rpt_opt";

export default {
    name: 'purchase_order_rpt',
    components: {Results, Fields, GroupBy, Options},
    data() {
        return {
            state: store.state,
            optionFields: {
                poStartDate: {
                    val: new Date(new Date().setMonth(new Date().getMonth() - 3)).toLocaleDateString(),
                    qsField: '[dates][purchase_order_date_from]'
                },
                poEndDate: {val: new Date().toLocaleDateString(), qsField: '[dates][purchase_order_date_to]'},
                deliveryStartDate: {val: '', qsField: '[dates][purchase_order_delivery_date_from]'},
                deliveryEndDate: {val: '', qsField: '[dates][purchase_order_delivery_date_to]'},
                cdiLocationId: {val: 0, qsField: '[numbers][po.cdi_location_id]'},
                companyName: {val: ''},
                companyId: {val: '', qsField: '[numbers][po.company_id]'},
                purchasedItemName: {val: ''},
                purchasedItemId: {val: '', qsField: '[numbers][poi.raw_material_id]'},
                inventoryCategory: {val: 0, qsField: '[numbers][rm.inventory_category_id]'},
                assetName: {val: ''},
                assetId: {val: '', qsField: '[numbers][a.asset_id]', defaultVal: ''},
                corporate: {val: 0, qsField: '[numbers][corporate]', defaultVal: 0},
                ato: {val: 0, qsField: '[numbers][po.ato]', defaultVal: 0},
            },
            groupByFields: {
                purchase_order_id: {label: 'PO', checked: true},
                company_name: {label: 'Company', checked: false},
                cdi_location: {label: 'Location', checked: false},
                raw_material: {label: 'Purchased Item', checked: false}
            },
            dataFields: {
                purchase_order_id: {label: 'PO', checked: true},
                purchase_order_date: {label: 'Date', checked: true},
                sum_purchase_orders_total: {label: 'Total', checked: true},
                cdi_location: {label: 'Location', checked: true},
                company_name: {label: 'Company', checked: true},
                raw_material: {label: 'Purchased Item', checked: true},
                item_weight: {label: 'Weight', checked: false},
                note: {label: 'Note', checked: false},
                workingdays_to_delivery: {label: 'Delivery Days', checked: true},
                asset_id: {label: 'Asset', checked: false},
                material_order_quantity: {label: 'Purchased Item Qty', checked: true},
                material_order_price: {label: 'Purchased Item Price', checked: true},
                corporate: {label: 'Corporate', checked: false},
                ato: {label: 'ATO', checked: false},

            }
        }
    },
    methods: {
        runReport() {
            store.runReport();
        },
    },
    created() {
        this.$appStore.setTitle(['Purchase Orders']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    }
}
</script>
<style scoped>

</style>